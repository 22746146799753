import Tag from 'components/Tag/Tag'
import { SubscriptionStatus } from 'models'

export type StatusTagProps = { status: SubscriptionStatus }

export default function StatusTag({ status }: StatusTagProps) {
  switch (status) {
    case SubscriptionStatus.Canceled:
      return <Tag label='Canceled' color='red' />
    case SubscriptionStatus.Restricted:
      return <Tag label='Restricted' color='red' />
    case SubscriptionStatus.Trialing:
      return <Tag label='Trial' color='yellow' />
    case SubscriptionStatus.ProofOfConcept:
      return <Tag label='Proof of concept' color='yellow' />
    case SubscriptionStatus.Unpaid:
      return <Tag label='Payment required' color='red' />
    case SubscriptionStatus.PastDue:
      return <Tag label='Retrying payment' color='yellow' />
    default:
      return <Tag label='Active' color='green' />
  }
}
