import { BadgeTone } from '@compass/components'
import { formatPercent } from 'helpers/format'
import { UpdateUrgency } from 'models'

export const URGENCY_STATUS_MAP: Record<UpdateUrgency, BadgeTone> = {
  unavailable: 'success',
  available: 'info',
  recommended: 'warning',
  required: 'danger',
  critical: 'void',
}

export const URGENCY_LABEL_MAP: Record<UpdateUrgency, string> = {
  available: 'Update available',
  recommended: 'Update recommended',
  required: 'Update required',
  critical: 'Critical update required',
  unavailable: 'Latest',
}

export const getThrottleBadgeColor = (percentage: number): BadgeTone => {
  if (percentage >= 0.4) {
    return 'danger'
  }

  if (percentage >= 0.15) {
    return 'warning'
  }

  if (percentage > 0) {
    return 'info'
  }

  return 'success'
}

export const getThrottleLabel = (percentage?: number | null): string => {
  if (percentage == null) {
    return 'N/A'
  }

  if (percentage >= 0.4) {
    return 'Needs attention'
  }

  if (percentage >= 0.15) {
    return 'Medium'
  }

  if (percentage > 0) {
    return 'Low'
  }

  return 'Stable'
}

export const getThrottleValue = (percentage?: number) => formatPercent(percentage ?? 0)
