import { ChevronRight } from '@mui/icons-material'
import { Link, Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styles from './PlanSummary.module.scss'

export interface SummaryEntry {
  key: string
  value?: string | number | ReactNode
  action?: string
  onAction?: () => void
  actionTo?: string
  badge?: ReactNode
  hidden?: boolean
}

export interface PlanSummaryProps {
  entries: SummaryEntry[]
  isLoading?: boolean
  className?: string
}

export function PlanSummary({ entries, isLoading, className }: PlanSummaryProps) {
  return (
    <ol className={clsx(styles.settingsSummary, className)}>
      {entries
        .filter(({ hidden }) => !hidden)
        .map(({ key, value, action, onAction, actionTo, badge }) => (
          <li key={key} data-testid={`summary-${key}`} className={styles.entry}>
            <Typography variant='bodyS' component='span' className={styles.label}>
              {key}
            </Typography>
            <div className={styles.nameWrapper}>
              <Typography variant='bodyMMedium' component='span'>
                {isLoading ? <Skeleton width={64} /> : value}
              </Typography>
              {badge}
            </div>

            {action && (actionTo || onAction) && (
              <Link
                component={actionTo ? RouterLink : 'button'}
                variant='semiBody2'
                to={actionTo}
                onClick={onAction}
                underline='hover'
                className={clsx(styles.action, { [styles.disabled]: isLoading })}
                disabled={isLoading}
                color={isLoading ? 'inherit' : 'primary'}
              >
                {action} <ChevronRight fontSize='small' />
              </Link>
            )}
          </li>
        ))}
    </ol>
  )
}
