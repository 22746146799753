import { Skeleton, Typography } from '@mui/material'
import { STATUS_COLOR_MAP, SUBSCRIPTION_STATUSES } from 'const'
import { SubscriptionStatus } from 'models'

import styles from './SubscriptionStatusDot.module.scss'

interface SubscriptionStatusDotProps {
  status?: SubscriptionStatus
  className?: string
}

export function SubscriptionStatusDot({ status }: SubscriptionStatusDotProps) {
  const label = status ? SUBSCRIPTION_STATUSES[status].name : undefined
  const color = status ? STATUS_COLOR_MAP[status] : ''

  return (
    <div className={styles.status} data-color={color}>
      <Typography variant='bodyMMedium'>{label ?? <Skeleton width={80} data-testid='skeleton' />}</Typography>
    </div>
  )
}
