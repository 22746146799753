import { LinkButton } from '@compass/components'
import { Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { formatNum } from 'helpers/format'
import { KeyRoundIcon, SettingsIcon } from 'lucide-react'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import { getPlanTagLabel } from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsList.module.scss'

export type ApplicationsListProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsList({ subscriptions }: ApplicationsListProps) {
  return (
    <div>
      {subscriptions.map((it) => (
        <div key={it.id} className={styles.applicationsListItem}>
          <div className={clsx(styles.applicationsListItemRow, styles.applicationsListItemRowHeader)}>
            <div className={styles.property}>
              <Typography variant='bodyMMedium' className={styles.primaryLabel}>
                {it.name}
              </Typography>
              <Typography variant='bodyM' className={styles.secondaryLabel}>
                {it.domain}
              </Typography>
            </div>

            <StatusTag status={it.status} />
          </div>
          <div className={clsx(styles.applicationsListItemRow, styles.applicationsListItemRowDetails)}>
            <div className={styles.propertySet}>
              <div className={styles.property}>
                <Typography variant='bodyS' className={styles.subheaderLabel}>
                  Plan
                </Typography>
                <Typography variant='bodyMMedium' className={styles.secondaryLabel}>
                  {getPlanTagLabel(it)}
                </Typography>
              </div>
              <div className={styles.property}>
                <Typography variant='bodyS' className={styles.subheaderLabel}>
                  Usage
                </Typography>
                <Typography variant='bodyMMedium' className={styles.secondaryLabel}>
                  {it.usageQuota?.billed ? formatNum(it.usageQuota.billed) : '-'}
                </Typography>
              </div>
            </div>

            <div className={styles.actionSet}>
              <LinkButton variant='outline' href={buildRoute(AppRoute.ApiKeys, { subscriptionId: it.id })}>
                <KeyRoundIcon />
                Keys
              </LinkButton>

              <LinkButton variant='outline' href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: it.id })}>
                <SettingsIcon />
                Manage
              </LinkButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
