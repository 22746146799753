import { SubscriptionStatusDot } from 'components/SubscriptionStatusDot/SubscriptionStatusDot'
import { useCurrentSubscription } from 'features/subscription'
import { useSubscription } from 'hooks'
import { SubscriptionStatus } from 'models'

import { Widget } from './Widget'

export function StatusWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <StatusWidgetContent status={subscription?.status} />
}

export function StatusWidgetContent({ status }: { status?: SubscriptionStatus }) {
  return (
    <Widget title='Status'>
      <SubscriptionStatusDot status={status} />
    </Widget>
  )
}
