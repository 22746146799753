import { IntegrationData, IntegrationProvider, ProxyIntegrationRequests } from 'models'
import { useMemo } from 'react'

import { useCloudflareIntegrationData } from '../../hooks/api/integrations'
import { useCloudFrontIntegrationData } from '../../hooks/api/integrations_cloudfront'
import {
  IntegrationCategory,
  integrationList,
  IntegrationMetadata,
  ThirdPartyIntegration,
} from './components/IntegrationMetadata/integrationMetadata'
import { useIntegrationStatsQuery } from './hooks/useIntegrationStatsQuery'

export function useIntegrationData(subscriptionId: string) {
  const { data: cloudflareIntegrationData, isLoading: isLoadingCloudflareData } =
    useCloudflareIntegrationData(subscriptionId)

  let integrationData: IntegrationData | undefined
  if (cloudflareIntegrationData) {
    integrationData = {
      provider: IntegrationProvider.Cloudflare,
      ...cloudflareIntegrationData,
    }
  }

  return {
    integrationData,
    isLoading: isLoadingCloudflareData,
  }
}

function calculateIntegrationRequestCount(requests: ProxyIntegrationRequests | undefined): number {
  return requests?.proxyRequests.map((it) => it.value).reduce((sum, curr) => sum + curr, 0) || 0
}

export function useIntegrationsMetadata(subscriptionId: string) {
  const cloudflareSpecificData = useCloudflareIntegrationData(subscriptionId)
  const cloudFrontSpecificData = useCloudFrontIntegrationData(subscriptionId)

  const fastlyVCLStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.FastlyVCL, subscriptionId)
  const fastlyComputeStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.FastlyCompute, subscriptionId)
  const cloudFrontStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.CloudFront, subscriptionId)
  const customIntegrationStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.CustomProxy, subscriptionId)
  const azureStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.Azure, subscriptionId)
  const akamaiStatsQuery = useIntegrationStatsQuery(ThirdPartyIntegration.Akamai, subscriptionId)

  const integrationsMetadata: IntegrationMetadata[] = useMemo(() => {
    return integrationList
      .map((integration) => {
        switch (integration.integrationTag) {
          default:
            return integration
          case ThirdPartyIntegration.Cloudflare:
            if (cloudflareSpecificData.data) {
              return {
                ...integration,
                category: IntegrationCategory.Active,
              }
            }
            break
          case ThirdPartyIntegration.CloudFront:
            {
              const requestCount = calculateIntegrationRequestCount(cloudFrontStatsQuery.data)
              const isInstalled = cloudFrontSpecificData.data?.isEnabled || requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
          case ThirdPartyIntegration.FastlyVCL:
            {
              const requestCount = calculateIntegrationRequestCount(fastlyVCLStatsQuery.data)
              const isInstalled = requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
          case ThirdPartyIntegration.FastlyCompute:
            {
              const requestCount = calculateIntegrationRequestCount(fastlyComputeStatsQuery.data)
              const isInstalled = requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
          case ThirdPartyIntegration.CustomProxy:
            {
              const requestCount = calculateIntegrationRequestCount(customIntegrationStatsQuery.data)
              const isInstalled = requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
          case ThirdPartyIntegration.Azure:
            {
              const requestCount = calculateIntegrationRequestCount(azureStatsQuery.data)
              const isInstalled = requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
          case ThirdPartyIntegration.Akamai:
            {
              const requestCount = calculateIntegrationRequestCount(akamaiStatsQuery.data)
              const isInstalled = requestCount > 0
              if (isInstalled) {
                return {
                  ...integration,
                  category: IntegrationCategory.Active,
                }
              }
            }
            break
        }

        return integration
      })
      .filter((integration) => integration.category !== IntegrationCategory.CustomIntegrations)
  }, [
    cloudflareSpecificData.data,
    cloudFrontSpecificData.data,
    fastlyVCLStatsQuery.data,
    fastlyComputeStatsQuery.data,
    cloudFrontStatsQuery.data,
    customIntegrationStatsQuery.data,
    azureStatsQuery.data,
    akamaiStatsQuery.data,
  ])

  return useMemo(() => ({ integrationsMetadata }), [integrationsMetadata])
}

export function useIntegrationByTag(integrationTag: string) {
  return useMemo(() => integrationList.find((it) => it.integrationTag === integrationTag), [integrationTag])
}

export function useWebIntegrationMetadata() {
  return useMemo(() => integrationList.filter((it) => it.category === IntegrationCategory.WebLibraries), [])
}

export function useMobileIntegrationMetadata() {
  return useMemo(() => integrationList.filter((it) => it.category === IntegrationCategory.MobileLibraries), [])
}
