import { Badge } from '@compass/components'
import { Link } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DashboardWidgetLink } from 'components/DashboardWidget/Link/DashboardWidgetLink'
import { SUPPORT_PAGE_URL } from 'const'
import { useCurrentSubscription } from 'features/subscription'
import { HealthIndicatorSeverity } from 'models'

import { HealthWidget, HealthWidgetBody } from '../HealthWidget'
import { getThrottleBadgeColor, getThrottleLabel, getThrottleValue } from '../utils'
import { WidgetSeverityIndicator } from '../WidgetSeverityIndicator/WidgetSeverityIndicator'

export interface ThrottledRequestsWidgetProps {
  severity: HealthIndicatorSeverity
  throttledRequestsPercentage: number | null
  className?: string
}

export function ThrottledRequestsHealthWidget({
  severity,
  throttledRequestsPercentage,
  className,
}: ThrottledRequestsWidgetProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()

  return (
    <HealthWidget
      title='Percentage of Throttled Requests'
      link={
        throttledRequestsPercentage == null ? (
          <DashboardWidgetLink
            to={buildRoute(AppRoute.Integrations, { subscriptionId })}
            text='Go to integrations page'
          />
        ) : (
          <DashboardWidgetLink to={buildRoute(AppRoute.ApiKeys, { subscriptionId })} text='Manage API keys' />
        )
      }
      badgeTone={throttledRequestsPercentage != null ? getThrottleBadgeColor(throttledRequestsPercentage) : 'secondary'}
      label={getThrottleLabel(throttledRequestsPercentage)}
      className={className}
    >
      <ThrottledRequestsHealthWidgetInfo
        severity={severity}
        throttledRequestsPercentage={throttledRequestsPercentage}
      />
    </HealthWidget>
  )
}

function ThrottledRequestsHealthWidgetInfo({ severity, throttledRequestsPercentage }: ThrottledRequestsWidgetProps) {
  if (throttledRequestsPercentage === null) {
    return (
      <HealthWidgetBody
        icon={<WidgetSeverityIndicator severity={severity} />}
        title='No data'
        subtitle='You haven&#39;t sent any requests yet'
      />
    )
  }

  if (throttledRequestsPercentage === 0) {
    return (
      <HealthWidgetBody
        icon={<WidgetSeverityIndicator severity={severity} />}
        title='All requests are getting through'
        subtitle='Congrats, no throttled requests!'
      />
    )
  }

  return (
    <HealthWidgetBody
      icon={
        <Badge tone={getThrottleBadgeColor(throttledRequestsPercentage)} variant='solid'>
          {getThrottleValue(throttledRequestsPercentage)}
        </Badge>
      }
      title='Review your JS agent'
      subtitle={
        <>
          Please ensure your JS agent is set up correctly and requests are not being sent in rapid succession. If you
          have questions, please{' '}
          <Link href={SUPPORT_PAGE_URL} underline='hover' target='_blank'>
            contact support
          </Link>
          .
        </>
      }
    />
  )
}
