import { Typography } from '@mui/material'
import { BigIntValue, DataCard } from 'features/commonUI'
import { CustomDateRange } from 'helpers/dateRange'
import { formatPercent } from 'helpers/format'

import { SmartSignalMetric } from '../../hooks/useSmartSignalsMetrics'
import { DiffType } from '../../utils/models'
import { PlatformFilterIndicator } from '../PlatformFilterIndicator/PlatformFilterIndicator'
import styles from './SmartSignalMetricCard.module.scss'

interface SmartSignalCardProps {
  smartSignalMetricData: SmartSignalMetric
  totalApiCalls?: bigint | null
  diffType: DiffType
  compareDateRange?: CustomDateRange
}
export function SmartSignalCard({
  smartSignalMetricData,
  totalApiCalls,
  diffType,
  compareDateRange,
}: SmartSignalCardProps) {
  const currentSmartSignalValue = smartSignalMetricData.value ? smartSignalMetricData.value : 0n

  return (
    <DataCard
      title={smartSignalMetricData.label}
      info={smartSignalMetricData.tooltip}
      subtitle={<PlatformFilterIndicator platforms={smartSignalMetricData.platform} />}
    >
      <div className={styles.subtitleContainer}>
        <Typography variant='h2'>
          {diffType === 'absolute' ? (
            <BigIntValue
              compareDateRange={compareDateRange}
              availabilityBoundary={smartSignalMetricData.availabilityBoundary}
              value={smartSignalMetricData.value ?? 0n}
              previousValue={smartSignalMetricData.previousPeriodValue ?? 0n}
            />
          ) : null}
          {diffType === 'percent' && totalApiCalls != null
            ? formatPercent(Number((currentSmartSignalValue * 10_000n) / BigInt(totalApiCalls)) / 10_000, true)
            : null}
        </Typography>

        <Typography variant='bodyS' color='gray'>
          API Calls
        </Typography>
      </div>
    </DataCard>
  )
}
