import { ArrowRight, Check, Info, X } from 'lucide-react'

import { HTTP_STATUS } from '../../../const/HttpStatus'
import { getHttpStatusRange } from '../../../helpers/common'
import Tag, { TagProps } from '../Tag'

export interface HttpStatusTagProps {
  status: keyof typeof HTTP_STATUS
  className?: string
}

export default function HttpStatusTag({ status, className }: HttpStatusTagProps) {
  const statusRange = getHttpStatusRange(status)

  return (
    <Tag
      label={`${status} ${HTTP_STATUS[status]}`}
      color={getStatusColor(statusRange)}
      icon={getStatusIcon(statusRange)}
      className={className}
    />
  )
}

function getStatusColor(statusRange: number): TagProps['color'] {
  switch (statusRange) {
    case 100:
      return 'blue'
    case 200:
      return 'green'
    case 300:
      return 'yellow'
    case 400:
    case 500:
      return 'red'
    default:
      return 'gray'
  }
}

function getStatusIcon(statusRange: number): TagProps['icon'] {
  switch (statusRange) {
    case 100:
      return <Info />
    case 200:
      return <Check />
    case 300:
      return <ArrowRight />
    case 400:
    case 500:
      return <X />
    default:
      return undefined
  }
}
