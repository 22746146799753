import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useCurrentUser } from 'hooks/api/users'
import { useSubscriptionStatsQuery } from 'hooks/subscriptionStats'
import { DateTime } from 'luxon'
import { statisticsAvailabilityBoundaries, SubscriptionStatistic } from 'models/stats'
import { useMemo } from 'react'

export function useInsightsStats() {
  const { dateRange } = useDateRangeContext()
  const { data: currentUser } = useCurrentUser()

  const { currentSubscriptionId } = useCurrentSubscription()
  const { data, error, isLoading } = useSubscriptionStatsQuery(
    currentSubscriptionId,
    dateRange?.startDate?.toUTC()?.toISO() ?? undefined,
    dateRange?.endDate?.toUTC()?.toISO() ?? undefined,
    currentUser != null
  )

  return useMemo(
    () => ({
      data: data?.stats,
      error: !!error,
      isLoading,
      dateRange,
      availability: data?.stats?.reduce(
        (acc, stat) => {
          const boundary = statisticsAvailabilityBoundaries[stat.type]
          if (dateRange?.compareStart && boundary && dateRange?.compareStart < boundary) {
            acc[stat.type] = boundary
          }
          return acc
        },
        {} as Record<SubscriptionStatistic, DateTime>
      ),
    }),
    [data?.stats, dateRange, error, isLoading]
  )
}
