import { Badge } from '@compass/components'
import { Tooltip } from '@mui/material'
import { formatDate } from 'features/commonUI'
import { date } from 'helpers/data'
import { DateTime } from 'luxon'
import {
  BillingType,
  CouponDurationType,
  Discount,
  ExpandedSubscription,
  SubscriptionStatus,
  SubscriptionType,
} from 'models'

interface PlanBadgeProps {
  subscription: ExpandedSubscription
  className?: string
}

const getAppliedDiscount = (discounts: Discount[] = []): Discount | null => {
  return (
    discounts.reduce<Discount | null>((acc, d) => {
      // Find discount with duration === 'repeating' and with the most percents off
      return d.coupon?.duration === CouponDurationType.Repeating &&
        (acc === null || (d.coupon?.percentOff ?? 0) > (acc.coupon?.percentOff ?? 0))
        ? d
        : acc
    }, null) ?? null
  )
}

const DowngradeBadge = (downgradeScheduledAt: Date) => (
  <Tooltip title={`The workspace's plan will downgrade to Fingerprint Pro on ${formatDate(downgradeScheduledAt)}`}>
    <div>
      <Badge tone='warning' variant='solid'>
        {`Downgrades ${formatDate(downgradeScheduledAt, 'short')}`}
      </Badge>
    </div>
  </Tooltip>
)

const ProofOfConceptBadge = () => (
  <Tooltip title='Your workspace is trialing Fingerprint Enterprise as a proof of concept.'>
    <div>
      <Badge tone='special' variant='subtle'>
        Proof of concept
      </Badge>
    </div>
  </Tooltip>
)

const TrialEndedBadge = () => (
  <Badge tone='warning' variant='subtle'>
    Trial ended
  </Badge>
)

const UniqueVisitorsBadge = (isEnterprise: boolean) => (
  <Tooltip
    title={`You are billed per unique visitorId.${
      !isEnterprise ? ' Upgrade or contact support' : ' Contact support'
    } to be billed per API call.`}
  >
    <div>
      <Badge tone='warning' variant='subtle'>
        Unique visitors billing
      </Badge>
    </div>
  </Tooltip>
)

const DiscountBadge = (discount: Discount) => {
  const createDate = new Date(discount.createdAt)
  createDate.setMonth(createDate.getMonth() + (discount.coupon?.durationInMonths ?? 0))
  return (
    <Tooltip
      title={`Your plan has a ${discount.coupon?.percentOff}% discount in price until ${formatDate(createDate)}.`}
    >
      <div>
        <Badge tone='info' variant='subtle'>
          Discount applied
        </Badge>
      </div>
    </Tooltip>
  )
}

export function PlanBadge({ subscription }: PlanBadgeProps) {
  const willDowngrade = !!(
    subscription?.downgradeScheduledAt && DateTime.now() < DateTime.fromJSDate(date(subscription?.downgradeScheduledAt))
  )

  if (willDowngrade && subscription.downgradeScheduledAt) {
    return DowngradeBadge(date(subscription.downgradeScheduledAt))
  }

  if (subscription.type === SubscriptionType.ProofOfConcept) {
    return ProofOfConceptBadge()
  }

  if (subscription.type === SubscriptionType.TrialOnly && subscription.status === SubscriptionStatus.Canceled) {
    return TrialEndedBadge()
  }

  if (subscription.billingType === BillingType.UniqueVisitors) {
    const isEnterprise = subscription?.type === SubscriptionType.Prepaid
    return UniqueVisitorsBadge(isEnterprise)
  }

  const appliedDiscount = getAppliedDiscount(subscription.discounts)
  if (appliedDiscount) {
    return DiscountBadge(appliedDiscount)
  }

  switch (subscription.status) {
    case SubscriptionStatus.Restricted:
      if (subscription.type === SubscriptionType.TrialOnly) {
        return (
          <Badge tone='warning' variant='subtle'>
            Restricted
          </Badge>
        )
      }
      return null

    case SubscriptionStatus.Unpaid:
      return (
        <Badge tone='warning' variant='solid'>
          Unpaid
        </Badge>
      )

    case SubscriptionStatus.PastDue:
      return (
        <Badge tone='danger' variant='solid'>
          Past due
        </Badge>
      )

    case SubscriptionStatus.Active:
      if (subscription.cancelAt) {
        return (
          <Badge tone='danger' variant='solid'>
            {`Cancels ${formatDate(subscription.cancelAt, 'short')}`}
          </Badge>
        )
      }
      return null

    default:
      return null
  }
}
