import Tag from 'components/Tag/Tag'
import { SdkRowDetails } from 'models'

import { URGENCY_LABEL_MAP, URGENCY_STATUS_MAP } from '../widgets/utils'

type VersionStatusTagProps = {
  row?: SdkRowDetails
}
export const VersionStatusTag = ({ row }: VersionStatusTagProps) => {
  if (row == null || (row.severity == null && row.updateUrgency == null)) {
    return null
  }

  const tone = URGENCY_STATUS_MAP[row.updateUrgency]
  const label = URGENCY_LABEL_MAP[row.updateUrgency]

  return <Tag tone={tone} label={label} />
}
