import { useMemo } from 'react'

import { DateRange, PredefinedRange } from '../../../helpers/dateRange'
import { useDateRangeContext } from '../components/DateRangeContext'

export const useDefaultPredefinedRanges = (): { ranges: Record<PredefinedRange['id'], PredefinedRange> } => {
  const { today } = useDateRangeContext()

  const ranges = useMemo(() => {
    const startOfToday = today.startOf('day')
    const endOfToday = today.endOf('day')
    const endOfYesterday = endOfToday.minus({ days: 1 })

    // Required invariant: all possible ranges must be listed here, see definition of PredefinedRange type
    const definedRanges: PredefinedRange[] = [
      {
        id: 'today',
        label: 'Today',
        startDate: startOfToday,
        endDate: today.startOf('minute'),
        compareStart: startOfToday.minus({ days: 1 }),
        compareEnd: endOfYesterday,
      },
      {
        id: 'last_7_days',
        label: 'Last 7 days',
        startDate: startOfToday.minus({ days: 7 }),
        endDate: endOfYesterday,
        compareStart: startOfToday.minus({ days: 14 }),
        compareEnd: endOfYesterday.minus({ days: 7 }),
      },
      {
        id: 'last_30_days',
        label: 'Last 30 days',
        startDate: startOfToday.minus({ days: 30 }),
        endDate: endOfYesterday,
        compareStart: startOfToday.minus({ days: 60 }),
        compareEnd: endOfYesterday.minus({ days: 30 }),
      },
      {
        id: 'last_3_months',
        label: 'Last 3 months',
        startDate: startOfToday.minus({ months: 3 }),
        endDate: endOfYesterday,
        compareStart: startOfToday.minus({ months: 6 }),
        compareEnd: endOfYesterday.minus({ months: 3 }),
      },
      {
        id: 'last_6_months',
        label: 'Last 6 months',
        startDate: startOfToday.minus({ months: 6 }),
        endDate: endOfYesterday,
        compareStart: startOfToday.minus({ months: 12 }),
        compareEnd: endOfYesterday.minus({ months: 6 }),
      },
      {
        id: 'last_12_months',
        label: 'Last 12 months',
        startDate: startOfToday.minus({ months: 12 }),
        endDate: endOfYesterday,
        compareStart: startOfToday.minus({ months: 24 }),
        compareEnd: endOfYesterday.minus({ months: 12 }),
      },
      {
        id: 'week_to_date',
        label: 'Week to date',
        // note: using bare startOf('week') here to start from Monday independently on locale, details in DASH-1550
        startDate: endOfYesterday.startOf('week'),
        endDate: endOfYesterday,
        compareStart: endOfYesterday.startOf('week').minus({ weeks: 1 }),
        compareEnd: endOfYesterday.minus({ weeks: 1 }),
      },
      {
        id: 'month_to_date',
        label: 'Month to date',
        startDate: endOfYesterday.startOf('month'),
        endDate: endOfYesterday,
        compareStart: endOfYesterday.startOf('month').minus({ months: 1 }),
        compareEnd: endOfYesterday.minus({ months: 1 }),
      },
    ]

    return Object.fromEntries(definedRanges.map((entry) => [entry.id, entry])) as Record<
      PredefinedRange['id'],
      PredefinedRange
    >
  }, [today])

  return { ranges }
}

export const toCustomDateRange = (range: Required<DateRange>) => {
  const diff = range.endDate.diff(range.startDate, 'days').days ?? 0
  return {
    ...range,
    compareStart: range.startDate.minus({ days: diff }),
    compareEnd: range.endDate.minus({ days: diff }),
  }
}
