import { CustomDateRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'

import { ValueWithDifference } from './ValueWithDifference'

export function NumericValue({
  value,
  previousValue,
  precise,
  availabilityBoundary,
  compareDateRange,
}: {
  value?: number
  previousValue?: number
  precise?: boolean
  availabilityBoundary?: DateTime
  compareDateRange?: CustomDateRange
}) {
  if (previousValue == null || previousValue === 0) {
    if (value == null || value === 0) {
      // No data for both current and previous period
      return (
        <ValueWithDifference
          value={0}
          difference={0}
          percentage={0}
          shouldShowDiff={false}
          precise={precise}
          availabilityBoundary={availabilityBoundary}
        />
      )
    } else {
      // Have data only for current period
      return (
        <ValueWithDifference
          value={value}
          difference={value}
          percentage={value === 0 ? 0 : 1}
          shouldShowDiff={false}
          precise={precise}
          availabilityBoundary={availabilityBoundary}
        />
      )
    }
  } else {
    if (value == null || value === 0) {
      // Have data only for previous period
      return (
        <ValueWithDifference
          value={0}
          difference={-previousValue}
          percentage={-1}
          shouldShowDiff={true}
          precise={precise}
          compareDateRange={compareDateRange}
          availabilityBoundary={availabilityBoundary}
        />
      )
    } else {
      // Have data for both current and previous periods
      const difference = value - previousValue
      const percentage = (value - previousValue) / previousValue
      const shownPercentage = Number.isFinite(percentage) ? percentage : percentage > 0 ? 1 : -1

      return (
        <ValueWithDifference
          value={value}
          difference={difference}
          percentage={shownPercentage}
          shouldShowDiff={true}
          precise={precise}
          compareDateRange={compareDateRange}
          availabilityBoundary={availabilityBoundary}
        />
      )
    }
  }
}
