import Tag from 'components/Tag/Tag'
import { SubscriptionDisplayPlan } from 'const'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { ExpandedSubscription } from 'models'

type PartialSubscriptionData = Pick<ExpandedSubscription, 'type' | 'latestTier' | 'products'>
type SubscriptionPlanTagProps = { subscription: PartialSubscriptionData }

export function getPlanTagLabel(subscription: PartialSubscriptionData) {
  const displayPlan = getSubscriptionDisplayPlan(subscription)

  switch (displayPlan) {
    case SubscriptionDisplayPlan.Enterprise:
      return 'Enterprise'
    case SubscriptionDisplayPlan.Plus:
    case SubscriptionDisplayPlan.Plus99:
      return 'Pro Plus'
    case SubscriptionDisplayPlan.Pro:
      return 'Pro'
    case SubscriptionDisplayPlan.Free:
      return 'Free'
  }
}

export default function PlanTag({ subscription }: SubscriptionPlanTagProps) {
  const displayPlan = getSubscriptionDisplayPlan(subscription)

  switch (displayPlan) {
    case SubscriptionDisplayPlan.Enterprise:
      return <Tag label='Enterprise' color='purple' />
    case SubscriptionDisplayPlan.Plus:
    case SubscriptionDisplayPlan.Plus99:
      return <Tag label='Pro Plus' color='orange' />
    case SubscriptionDisplayPlan.Pro:
      return <Tag label='Pro' color='blue' />
    case SubscriptionDisplayPlan.Free:
      return <Tag label='Free' color='gray' />
  }
}
