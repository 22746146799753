import { Badge } from '@compass/components'
import { Close } from '@mui/icons-material'
import { IconButton, Skeleton, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { DashboardWidgetLink } from 'components/DashboardWidget/Link/DashboardWidgetLink'
import {
  COMPANY_NAME,
  DOCS_QUICK_START_URL,
  DOCS_REQUEST_FILTERING_URL,
  DOCS_SUBDOMAIN_INTEGRATION_URL,
  DOCS_WEBHOOKS_URL,
  PRODUCT_NAME,
} from 'const'
import { useCurrentSubscription } from 'features/subscription'
import {
  IntegrationStep,
  IntegrationStepsInOrder,
  IntegrationStepsInOrderClickable,
  SubscriptionIntegrationStatus,
} from 'models'
import { useCallback, useEffect, useState } from 'react'

import styles from './IntegrationStatusWidget.module.scss'

export enum IntegrationStepState {
  Completed = 'completed',
  Active = 'active',
  Skipped = 'skipped',
  Inactive = 'inactive',
}

export interface IntegrationStatusWidgetProps {
  integrationStatus: SubscriptionIntegrationStatus
  onSkip: () => void
  onClose?: () => void
  isLoading?: boolean
}

export function IntegrationStatusWidget({
  integrationStatus,
  onSkip,
  isLoading,
  onClose,
}: IntegrationStatusWidgetProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { currentStep } = integrationStatus
  const [viewedStepIndex, setViewedStepIndex] = useState(IntegrationStepsInOrder.indexOf(currentStep))

  useEffect(() => {
    setViewedStepIndex(IntegrationStepsInOrder.indexOf(currentStep))
  }, [currentStep])

  const getStepState = useCallback(
    (index: number) => {
      const currentStepIndex = IntegrationStepsInOrder.indexOf(currentStep)
      if (index === currentStepIndex) {
        return IntegrationStepState.Active
      }

      const stepName = IntegrationStepsInOrderClickable[index]

      if (index > currentStepIndex) {
        return IntegrationStepState.Inactive
      }

      if (integrationStatus[stepName].isSetUp) {
        return IntegrationStepState.Completed
      }

      return IntegrationStepState.Skipped
    },
    [currentStep, integrationStatus]
  )

  const numOfSteps = IntegrationStepsInOrderClickable.length
  const showSkipButton =
    viewedStepIndex !== 0 && currentStep !== IntegrationStep.ApiCalls && currentStep !== IntegrationStep.Finished

  const isFinished = currentStep === IntegrationStep.Finished

  const actionUrl = setupLinkTo(currentSubscriptionId, IntegrationStepsInOrder[viewedStepIndex])
  const actionText =
    actionUrl == null
      ? undefined
      : getStepState(viewedStepIndex) === IntegrationStepState.Completed
        ? 'Configure'
        : 'Set up'

  return (
    <DashboardBanner
      vertical={true}
      title={
        <header className={styles.header}>
          <span>Integration status</span>
          <Badge tone='secondary' variant='subtle'>
            {`Step ${viewedStepIndex >= numOfSteps ? numOfSteps : viewedStepIndex + 1}/${numOfSteps}`}
          </Badge>
        </header>
      }
      action={actionText}
      actionUrl={actionUrl}
      secondaryAction={showSkipButton && !isFinished ? 'Skip' : undefined}
      onClickSecondaryAction={onSkip}
      className={styles.wrapper}
    >
      <section className={styles.body}>
        <Stepper
          activeStep={viewedStepIndex}
          connector={<></>} // we don't need the connector for this design
          alternativeLabel={true}
          nonLinear={true}
          classes={{ root: styles.stepper }}
        >
          {IntegrationStepsInOrderClickable.map((step, index) => {
            const state = getStepState(index)
            return (
              <Step
                key={step}
                onClick={() => setViewedStepIndex(index)}
                active={state === IntegrationStepState.Active}
                completed={state === IntegrationStepState.Completed}
                classes={{
                  alternativeLabel: styles.step,
                }}
                data-testid={`integration-status-stepper--${state}`}
              >
                <StepLabel
                  classes={{
                    labelContainer: clsx(styles.label, mapStepStateToClassName(state)),
                    iconContainer: styles.stepIcon,
                  }}
                />
              </Step>
            )
          })}
        </Stepper>
        {isLoading ? (
          <>
            <Skeleton height='48px' data-testid='integration-status-loader' />
            <Skeleton variant='rectangular' height='60px' />
          </>
        ) : (
          <IntegrationStepBody step={IntegrationStepsInOrder[viewedStepIndex]} />
        )}
      </section>
      {isFinished ? (
        <IconButton size='small' onClick={onClose} className={styles.closeButton} title='close'>
          <Close />
        </IconButton>
      ) : null}
    </DashboardBanner>
  )
}

function setupLinkTo(currentSubscriptionId: string, step: IntegrationStep) {
  let to = buildRoute(AppRoute.SubdomainNew, { subscriptionId: currentSubscriptionId })
  switch (step) {
    case IntegrationStep.ApiCalls:
    case IntegrationStep.Finished:
      return undefined
    case IntegrationStep.CustomSubdomain:
      break
    case IntegrationStep.TrafficRules:
      to = buildRoute(AppRoute.RequestFiltering, { subscriptionId: currentSubscriptionId })
      break
    case IntegrationStep.Webhooks:
      to = buildRoute(AppRoute.Webhooks, { subscriptionId: currentSubscriptionId })
  }

  return to
}

function mapStepStateToClassName(state: IntegrationStepState) {
  switch (state) {
    case IntegrationStepState.Completed:
      return styles.completed
    case IntegrationStepState.Active:
      return styles.active
    case IntegrationStepState.Skipped:
      return styles.skipped
    case IntegrationStepState.Inactive:
      return styles.inactive
  }
}

function IntegrationStepBody({ step }: { step: IntegrationStep }) {
  switch (step) {
    case IntegrationStep.ApiCalls:
      return (
        <>
          <Typography variant='bodyMMedium' component='h4'>
            API calls
          </Typography>
          <Typography variant='body1' className={styles.secondaryText}>
            Start making API calls to identify visitors on your page.
          </Typography>
          <DashboardWidgetLink
            className={styles.link}
            href={DOCS_QUICK_START_URL}
            text={'Quick start guide'}
            target='_blank'
          />
        </>
      )
    case IntegrationStep.CustomSubdomain:
      return (
        <>
          <Typography variant='bodyMMedium' component='h4'>
            Custom subdomain
          </Typography>
          <Typography variant='body1' className={styles.secondaryText}>
            Set up your subdomain to improve identification accuracy.
          </Typography>
          <DashboardWidgetLink
            className={styles.link}
            href={DOCS_SUBDOMAIN_INTEGRATION_URL}
            text={`More about ${COMPANY_NAME} subdomain setup`}
            target='_blank'
          />
        </>
      )
    case IntegrationStep.TrafficRules:
      return (
        <>
          <Typography variant='bodyMMedium' component='h4'>
            Request filtering
          </Typography>
          <Typography variant='body1' className={styles.secondaryText}>
            Set up rules to filter requests made with your public token.
          </Typography>
          <DashboardWidgetLink
            className={styles.link}
            href={DOCS_REQUEST_FILTERING_URL}
            text={'More about request filtering'}
            target='_blank'
          />
        </>
      )
    case IntegrationStep.Webhooks:
      return (
        <>
          <Typography variant='bodyMMedium' component='h4'>
            Webhooks
          </Typography>
          <Typography variant='body1' className={styles.secondaryText}>
            Set up webhooks to get real-time visitor data on your server.
          </Typography>
          <DashboardWidgetLink
            className={styles.link}
            href={DOCS_WEBHOOKS_URL}
            text={`More about ${COMPANY_NAME} webhooks`}
            target='_blank'
          />
        </>
      )
    case IntegrationStep.Finished:
    default:
      return (
        <>
          <Typography variant='bodyMMedium' component='h4'>
            Congratulations!
          </Typography>
          <Typography variant='body1' className={styles.secondaryText}>
            You are now set up to get most out of your {PRODUCT_NAME} workspace.
          </Typography>
        </>
      )
  }
}
