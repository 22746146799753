import { DateTime } from 'luxon'

import { CustomDateRange } from '../../../../helpers/dateRange'
import { ValueWithDifference } from './ValueWithDifference'

export function BigIntValue({
  value,
  previousValue,
  availabilityBoundary,
  compareDateRange,
}: {
  value?: bigint
  previousValue?: bigint
  availabilityBoundary?: DateTime
  compareDateRange?: CustomDateRange
}) {
  if (previousValue == null || previousValue === 0n) {
    if (value == null || value === 0n) {
      // No data for both current and previous period
      return (
        <ValueWithDifference
          value={0}
          difference={0}
          percentage={0}
          shouldShowDiff={false}
          availabilityBoundary={availabilityBoundary}
        />
      )
    } else {
      // Have data only for current period
      return (
        <ValueWithDifference
          value={value}
          difference={value}
          percentage={value === 0n ? 0 : 1}
          shouldShowDiff={false}
          availabilityBoundary={availabilityBoundary}
        />
      )
    }
  } else {
    if (value == null || value === 0n) {
      // Have data only for previous period
      return (
        <ValueWithDifference
          value={0}
          difference={-previousValue}
          percentage={-1}
          shouldShowDiff={true}
          compareDateRange={compareDateRange}
          availabilityBoundary={availabilityBoundary}
        />
      )
    } else {
      // Have data for both current and previous periods
      const difference = value - previousValue
      const percentage = Number((difference * 100_000n) / previousValue) / 100_000
      const shownPercentage = Number.isFinite(percentage) ? percentage : percentage > 0 ? 1 : -1

      return (
        <ValueWithDifference
          shouldShowDiff={true}
          value={value}
          difference={difference}
          percentage={shownPercentage}
          compareDateRange={compareDateRange}
          availabilityBoundary={availabilityBoundary}
        />
      )
    }
  }
}
