import { Stack } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import {
  ContentColumn,
  DateRangeContextProvider,
  Header,
  interpretDateRange,
  MainColumn,
  PersistentDateRangePicker,
  smartSignalsNavItems,
  SubHeader,
  TabNavigation,
  useDateRangeContext,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useCurrentUser } from 'hooks/api/users'
import { groupBy } from 'lodash'
import { Settings } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

import { DiffTypeSelector } from '../../components/DiffTypeSelector/DiffTypeSelector'
import { PlatformSelector, PlatformType } from '../../components/PlatformSelector/PlatformSelector'
import { SmartSignalsIntroduction } from '../../components/SmartSignalsIntroduction/SmartSignalsIntroduction'
import { SmartSignalsMetricsContent } from '../../components/SmartSignalsMetricsContent/SmartSignalsMetricsContent'
import { SmartSignalMetric, useSmartSignalMetrics } from '../../hooks/useSmartSignalsMetrics'
import { DiffType } from '../../utils/models'

export function SmartSignalsPage() {
  const [tzReady, setTzReady] = useState(false)
  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])

  return tzReady ? (
    <DateRangeContextProvider>
      <Page />
    </DateRangeContextProvider>
  ) : (
    <>
      <Header title='Smart Signals' />
      <TabNavigation items={smartSignalsNavItems} />
    </>
  )
}

function Page() {
  const { data: currentUser } = useCurrentUser()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { dateRange, today } = useDateRangeContext()

  const [diffType, setDiffType] = useState<DiffType>('absolute')
  const [signalTypeFilter, setSignalTypeFilter] = useState<PlatformType[]>(['js', 'android', 'ios'])

  const { smartSignalsMetrics, smartSignalsEnabled, totalApiCalls, isLoading } = useSmartSignalMetrics(dateRange)
  const { ranges } = useDefaultPredefinedRanges()

  useEffect(() => {
    if (totalApiCalls == null && diffType === 'percent') {
      setDiffType('absolute')
    }
  }, [diffType, totalApiCalls])

  const metricsForSelectedPlatforms = smartSignalsMetrics.filter((metricData) =>
    metricData.platform.some((plat: PlatformType) => signalTypeFilter.includes(plat))
  )

  const totalMetrics = useMemo(() => reduceMetrics(smartSignalsMetrics), [smartSignalsMetrics])
  const displayedMetrics = useMemo(() => reduceMetrics(metricsForSelectedPlatforms), [metricsForSelectedPlatforms])

  const currentSignalsCount = displayedMetrics.length
  const highestSignalsCount = totalMetrics.length

  const allSmartSignalsEnabled = true
  const showUpsell =
    !isLoading &&
    smartSignalsEnabled &&
    (smartSignalsEnabled.length === 0 ||
      (smartSignalsEnabled.length === 1 && smartSignalsEnabled[0] === 'identification'))

  return (
    <>
      <Header title='Smart Signals' />
      <TabNavigation items={smartSignalsNavItems} />
      <MainColumn>
        {showUpsell ? (
          <SmartSignalsIntroduction />
        ) : (
          <ContentColumn>
            <SubHeader
              title={<Typography variant='h2'>Smart Signals statistics</Typography>}
              actions={
                <>
                  <Stack gap={2}>
                    <DiffTypeSelector
                      value={diffType}
                      onChange={(value) => {
                        if (value) {
                          setDiffType(value)
                        }
                      }}
                      disabled={totalApiCalls == null}
                    />
                    <PlatformSelector
                      value={signalTypeFilter}
                      onChange={setSignalTypeFilter}
                      metricsCount={currentSignalsCount}
                      maxMetricsCount={highestSignalsCount}
                    />
                  </Stack>
                  {today ? (
                    <PersistentDateRangePicker
                      defaultDateRange={ranges.last_7_days}
                      route={AppRoute.SmartSignals}
                      routeParams={{ subscriptionId: currentSubscriptionId }}
                      rangeInterpreter={(range) => interpretDateRange({ dateRange: range })}
                      timezone={currentUser?.timezone}
                    />
                  ) : (
                    <Skeleton width={200} height={32} className='transform-none' />
                  )}
                </>
              }
            />
            <SmartSignalsMetricsContent
              displayedMetricData={isLoading ? undefined : displayedMetrics}
              totalApiCalls={totalApiCalls}
              diffType={diffType}
              allSmartSignalsEnabled={allSmartSignalsEnabled}
              compareDateRange={dateRange}
            />
          </ContentColumn>
        )}
      </MainColumn>
    </>
  )
}

function reduceMetrics(metricsForSelectedPlatforms: SmartSignalMetric[]) {
  const metricsByLabel = groupBy(metricsForSelectedPlatforms, (m) => m.label)

  const metricsSummedByLabel = Object.keys(metricsByLabel).map((label) => {
    const metricsInDifferentPlatformCategories = metricsByLabel[label]
    let value: bigint | null = null
    let previousPeriodValue: bigint | null = null

    metricsInDifferentPlatformCategories.forEach((m) => {
      if (m.value !== null) {
        if (value === null) {
          value = m.value
        } else {
          value = value + m.value
        }
      }

      if (m.previousPeriodValue !== null) {
        if (previousPeriodValue === null) {
          previousPeriodValue = m.previousPeriodValue
        } else {
          previousPeriodValue = previousPeriodValue + m.previousPeriodValue
        }
      }
    })

    return {
      ...metricsInDifferentPlatformCategories[0],
      value,
      previousPeriodValue,
    }
  })

  const filteredMetrics = metricsSummedByLabel.filter((m) => m.value != null)
  const displayedMetrics = filteredMetrics.length > 0 ? filteredMetrics : metricsSummedByLabel

  return displayedMetrics
}
