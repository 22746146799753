import { DnsOutlined as IconCustom } from '@mui/icons-material'
import { lazy } from 'react'

import { DOCS_INTEGRATIONS } from '../../../../const'
import IconAkamai from './icons/akamai.svg'
import IconAndroid from './icons/android.svg'
import IconAngular from './icons/angular.svg'
import IconAws from './icons/aws.svg'
import IconAzure from './icons/azure.svg'
import IconCloudflare from './icons/cloudflare.svg'
import IconCsharp from './icons/csharp.svg'
import IconFastlyVCL from './icons/fastly-vcl.svg'
import IconFlutter from './icons/flutter.svg'
import IconGolang from './icons/golang.svg'
import IconGtm from './icons/gtm.svg'
import IconIos from './icons/ios.svg'
import IconJava from './icons/java.svg'
import IconJs from './icons/js.svg'
import IconMagento from './icons/magento.svg'
import IconNext from './icons/next.svg'
import IconNginx from './icons/nginx.svg'
import IconNodejs from './icons/nodejs.svg'
import IconPhp from './icons/php.svg'
import IconPreact from './icons/preact.svg'
import IconPython from './icons/python.svg'
import IconReact from './icons/react.svg'
import IconSegment from './icons/segment.svg'
import IconShopify from './icons/shopify.svg'
import IconSvelte from './icons/svelte.svg'
import IconVue from './icons/vue.svg'
import IconWordpress from './icons/wordpress.svg'

// Strict display order
export enum IntegrationCategory {
  Active = 'Manage your active integrations',
  ThirdParty = 'Platform integrations',
  WebLibraries = 'Web libraries',
  MobileLibraries = 'Mobile libraries',
  ServerLibraries = 'Server libraries',
  CustomIntegrations = 'Custom integrations',
}

export enum ThirdPartyIntegration {
  Cloudflare = 'cloudflare',
  GTM = 'gtm',
  Segment = 'segment',
  CloudFront = 'cloudfront',
  Azure = 'azure',
  Akamai = 'akamai',
  FastlyVCL = 'fastly-vcl',
  FastlyCompute = 'fastly-compute',
  Shopify = 'shopify',
  Wordpress = 'wordpress',
  Magento = 'magento',
  Nginx = 'nginx',
  CustomProxy = 'custom-proxy-integration',
}

// We separate SDK integrations from third party integrations for analytics purposes mostly
export enum SDKIntegration {
  JavaScript = 'javascript',
  NextJS = 'nextjs',
  React = 'react',
  Angular = 'angular',
  Vue = 'vue',
  Svelte = 'svelte',
  Preact = 'preact',
  Android = 'android',
  IOS = 'ios',
  Flutter = 'flutter',
  ReactNative = 'react-native',
  Node = 'node',
  DotNet = 'dotnet',
  Java = 'java',
  Golang = 'golang',
  PHP = 'php',
  Python = 'python',
}

export const sdkIntegrations: string[] = Object.values(SDKIntegration)

export type IntegrationMetadata = {
  title: string
  subtitle: string
  category: IntegrationCategory
  integrationTag: SDKIntegration | ThirdPartyIntegration // page slug and analytic label
  iconComponent: React.ComponentType<any> // TODO typing
  mdx?: React.LazyExoticComponent<any> | React.ComponentType<any>
  docsLink?: string
  sourceCodeLink?: string
  // If even we add new integration limits, we should reconsider this field
  isEnterpriseOnly?: boolean
  // For custom start page after signup, see GROW-162
  beforeContent?: React.ComponentType<any> // TODO typing
  afterContent?: React.ComponentType<any> // TODO typing
  // For Integrations Demands, see GROW-222
  isDemandTest?: boolean
}

export const integrationList: IntegrationMetadata[] = [
  // Web
  {
    title: 'JavaScript',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.JavaScript,
    iconComponent: IconJs,
    mdx: lazy(() => import('./docs/javascript.mdx')),
    docsLink: 'https://dev.fingerprint.com/reference/javascript-agent',
  },
  {
    title: 'Next.js',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.NextJS,
    iconComponent: IconNext,
    mdx: lazy(() => import('./docs/nextjs.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-react',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprintjs-pro-nextjs',
  },
  {
    title: 'React',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.React,
    iconComponent: IconReact,
    mdx: lazy(() => import('./docs/react.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-react',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprintjs-pro-react',
  },
  {
    title: 'Angular',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.Angular,
    iconComponent: IconAngular,
    mdx: lazy(() => import('./docs/angular.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-angular',
    docsLink: 'https://dev.fingerprint.com/docs/angular',
  },
  {
    title: 'Vue.js',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.Vue,
    iconComponent: IconVue,
    mdx: lazy(() => import('./docs/vue.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-vue',
    docsLink: 'https://dev.fingerprint.com/docs/vuejs',
  },
  {
    title: 'Preact',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.Preact,
    iconComponent: IconPreact,
    mdx: lazy(() => import('./docs/preact.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-react',
    docsLink: 'https://dev.fingerprint.com/docs/preact',
  },
  {
    title: 'Svelte',
    subtitle: 'Website',
    category: IntegrationCategory.WebLibraries,
    integrationTag: SDKIntegration.Svelte,
    iconComponent: IconSvelte,
    mdx: lazy(() => import('./docs/svelte.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-svelte',
    docsLink: 'https://dev.fingerprint.com/docs/svelte',
  },
  // Mobile
  {
    title: 'Android',
    subtitle: 'Mobile',
    category: IntegrationCategory.MobileLibraries,
    integrationTag: SDKIntegration.Android,
    iconComponent: IconAndroid,
    mdx: lazy(() => import('./docs/android.mdx')),
    docsLink: 'https://dev.fingerprint.com/docs/native-android-integration',
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-android-demo',
  },
  {
    title: 'iOS',
    subtitle: 'Mobile',
    category: IntegrationCategory.MobileLibraries,
    integrationTag: SDKIntegration.IOS,
    iconComponent: IconIos,
    mdx: lazy(() => import('./docs/ios.mdx')),
    docsLink: 'https://dev.fingerprint.com/docs/ios',
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-iOS',
  },
  {
    title: 'Flutter',
    subtitle: 'Mobile',
    category: IntegrationCategory.MobileLibraries,
    integrationTag: SDKIntegration.Flutter,
    iconComponent: IconFlutter,
    mdx: lazy(() => import('./docs/flutter.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-flutter',
    docsLink: 'https://dev.fingerprint.com/docs/flutter',
  },
  {
    title: 'React Native',
    subtitle: 'Mobile',
    category: IntegrationCategory.MobileLibraries,
    integrationTag: SDKIntegration.ReactNative,
    iconComponent: IconReact,
    mdx: lazy(() => import('./docs/react-native.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-react-native',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprintjs-pro-react-native',
  },

  // Server
  {
    title: 'Node.js',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.Node,
    iconComponent: IconNodejs,
    mdx: lazy(() => import('./docs/node.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-server-api-node-sdk',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprintjs-pro-server-api-nodejs-sdk',
  },
  {
    title: 'C#/.NET',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.DotNet,
    iconComponent: IconCsharp,
    mdx: lazy(() => import('./docs/dotnet.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-server-api-dotnet-sdk/',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprint-pro-server-api-dotnet-sdk',
  },
  {
    title: 'Java',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.Java,
    iconComponent: IconJava,
    mdx: lazy(() => import('./docs/java.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-server-api-java-sdk',
    docsLink: 'https://dev.fingerprint.com/docs/java-server-api-sdk',
  },
  {
    title: 'Golang',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.Golang,
    iconComponent: IconGolang,
    mdx: lazy(() => import('./docs/golang.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-server-api-go-sdk',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprint-pro-server-api-go-sdk',
  },
  {
    title: 'PHP',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.PHP,
    iconComponent: IconPhp,
    mdx: lazy(() => import('./docs/php.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-server-api-php-sdk',
    docsLink: 'https://dev.fingerprint.com/docs/fingerprint-pro-server-api-php-sdk',
  },
  {
    title: 'Python',
    subtitle: 'Server',
    category: IntegrationCategory.ServerLibraries,
    integrationTag: SDKIntegration.Python,
    iconComponent: IconPython,
    mdx: lazy(() => import('./docs/python.mdx')),
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-server-api-python-sdk',
    docsLink: 'https://dev.fingerprint.com/docs/python-server-api-sdk',
  },

  // Third party
  {
    title: 'Cloudflare',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Cloudflare,
    iconComponent: IconCloudflare,
    docsLink: DOCS_INTEGRATIONS.cloudflare,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-cloudflare-worker',
  },
  {
    title: 'Google Tag Manager',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.GTM,
    iconComponent: IconGtm,
    docsLink: DOCS_INTEGRATIONS.gtm,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprintjs-pro-gtm',
    mdx: lazy(() => import('./docs/gtm.mdx')),
  },
  {
    title: 'Segment',
    subtitle: 'Data Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Segment,
    iconComponent: IconSegment,
    docsLink: DOCS_INTEGRATIONS.segment,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-segment-source-function',
    mdx: lazy(() => import('./docs/segment.mdx')),
  },
  {
    title: 'AWS CloudFront',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.CloudFront,
    iconComponent: IconAws,
    docsLink: DOCS_INTEGRATIONS.cloudFront,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-cloudfront-integration',
    isEnterpriseOnly: true,
  },
  {
    title: 'Azure',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Azure,
    iconComponent: IconAzure,
    docsLink: DOCS_INTEGRATIONS.azure,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-azure-integration',
    mdx: lazy(() => import('./docs/azure.mdx')),
    isEnterpriseOnly: true,
  },
  {
    title: 'Akamai',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Akamai,
    iconComponent: IconAkamai,
    docsLink: DOCS_INTEGRATIONS.akamai,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-akamai-proxy-integration',
    mdx: lazy(() => import('./docs/akamai.mdx')),
    isEnterpriseOnly: true,
  },
  {
    title: 'Fastly Compute',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.FastlyCompute,
    iconComponent: IconFastlyVCL,
    docsLink: DOCS_INTEGRATIONS.fastlyCompute,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-fastly-compute-proxy-integration',
    mdx: lazy(() => import('./docs/fastly-compute.mdx')),
    isEnterpriseOnly: true,
  },
  {
    title: 'Fastly VCL',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.FastlyVCL,
    iconComponent: IconFastlyVCL,
    docsLink: DOCS_INTEGRATIONS.fastlyVcl,
    sourceCodeLink: 'https://github.com/fingerprintjs/fingerprint-pro-fastly-vcl-integration',
    mdx: lazy(() => import('./docs/fastly-vcl.mdx')),
    isEnterpriseOnly: true,
  },
  {
    title: 'Shopify',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Shopify,
    iconComponent: IconShopify,
    isDemandTest: true,
  },
  {
    title: 'Wordpress',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Wordpress,
    iconComponent: IconWordpress,
    isDemandTest: true,
  },
  {
    title: 'Magento',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Magento,
    iconComponent: IconMagento,
    isDemandTest: true,
  },
  {
    title: 'Nginx',
    subtitle: 'Integration',
    category: IntegrationCategory.ThirdParty,
    integrationTag: ThirdPartyIntegration.Nginx,
    iconComponent: IconNginx,
    isDemandTest: true,
  },
  {
    title: 'Custom Proxy Integration',
    subtitle: 'Integration',
    category: IntegrationCategory.CustomIntegrations,
    integrationTag: ThirdPartyIntegration.CustomProxy,
    iconComponent: IconCustom,
    docsLink: DOCS_INTEGRATIONS.customProxy,
    isEnterpriseOnly: false,
  },
]
