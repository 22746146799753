import { Badge, BadgeTone } from '@compass/components'
import { Tooltip } from '@mui/material'
import { colorToTone, TagColor } from 'helpers/colorToTone'
import { ReactElement, ReactNode } from 'react'

export interface TagProps {
  label?: string
  tooltip?: ReactNode
  color?: TagColor // TODO: Remove this once we've updated all the tags to use tone instead
  tone?: BadgeTone
  icon?: ReactElement
  className?: string
}

export default function Tag({ label, tooltip, color, tone: toneProp, icon, className }: TagProps) {
  const tone = toneProp || (color ? colorToTone(color) : 'secondary')

  const badge = (
    <Badge tone={tone} variant='solid' className={className}>
      {icon}
      {label}
    </Badge>
  )

  return tooltip ? (
    <Tooltip title={tooltip} arrow placement='top'>
      {badge}
    </Tooltip>
  ) : (
    badge
  )
}
