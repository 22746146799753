import { Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import Loader from 'components/Loader/Loader'
import { SortableTableHeadCell } from 'components/Table/Table'
import Tag from 'components/Tag/Tag'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { TagColor } from 'helpers/colorToTone'
import { date } from 'helpers/data'
import { formatDate } from 'helpers/format'
import { InvoiceListItem, InvoiceStatus, OrderLowercase } from 'models'
import { ampli } from 'models/ampli'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './InvoiceList.module.scss'

export interface InvoiceListProps {
  invoices: InvoiceListItem[]
  isLoading: boolean
  className?: string
  order?: 'asc' | 'desc'
  sortBy?: keyof InvoiceListItem
  page: number
  rowsPerPage: number
  totalCount: number
  onSort: (sortBy: keyof InvoiceListItem, order: OrderLowercase) => void
  onPageChange: (page: number) => void
}

export default memo(function InvoiceList({
  invoices,
  isLoading,
  className,
  order = 'desc',
  sortBy,
  page = 1,
  rowsPerPage,
  totalCount,
  onSort,
  onPageChange,
}: InvoiceListProps) {
  const history = useHistory()
  const handleRedirectToInvoice = (subscriptionId: string, invoiceId: string) => {
    ampli.viewInvoiceClicked()
    history.push(buildRoute(AppRoute.Invoice, { subscriptionId, invoiceId }))
  }
  const pageCount = Math.ceil(totalCount / rowsPerPage)

  return (
    <ContentColumn>
      <SubHeader title='Invoices' description='View invoices for all past and current charges to your account.' />
      <Paper className={clsx(styles.root, className)}>
        <Table className={clsx(styles.table)}>
          {totalCount === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell className={styles.zeroState} colSpan={3}>
                  <Typography>There are no invoices yet</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <SortableTableHeadCell
                    className={styles.firstColumn}
                    columnId={'periodStart'}
                    orderBy={sortBy}
                    order={order}
                    handleSort={onSort}
                  >
                    Period
                  </SortableTableHeadCell>
                  <SortableTableHeadCell columnId={'status'} orderBy={sortBy} order={order} handleSort={onSort}>
                    Status
                  </SortableTableHeadCell>
                  <SortableTableHeadCell columnId={'subscriptionId'} orderBy={sortBy} order={order} handleSort={onSort}>
                    Workspace
                  </SortableTableHeadCell>
                  <SortableTableHeadCell
                    columnId={'amountTotal'}
                    orderBy={sortBy}
                    order={order}
                    handleSort={onSort}
                    align='right'
                  >
                    Amount
                  </SortableTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow
                    key={invoice.id}
                    className={styles.invoiceRow}
                    onClick={() => handleRedirectToInvoice(invoice.subscriptionId, invoice.id)}
                  >
                    <TableCell className={styles.firstColumn}>{formatPeriod(invoice)}</TableCell>
                    <TableCell>
                      <Tag className={styles.tag} label={invoice.status} color={statusColors[invoice.status]} />
                    </TableCell>
                    <TableCell>{invoice.subscriptionName}</TableCell>
                    <TableCell align='right'>${invoice.amountTotal / 100}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
        {pageCount > 1 && (
          <Pagination
            className={styles.pagination}
            count={pageCount}
            page={page ?? 1}
            onChange={(_, newPage) => {
              onPageChange(newPage - 1)
            }}
          />
        )}

        {isLoading && <Loader testId='invoices-loader' />}
      </Paper>
    </ContentColumn>
  )
})

const statusColors: Record<InvoiceStatus, TagColor> = {
  [InvoiceStatus.Paid]: 'green',
  [InvoiceStatus.Open]: 'blue',
  [InvoiceStatus.Draft]: 'blue',
  [InvoiceStatus.Void]: 'red',
  [InvoiceStatus.Uncollectible]: 'red',
}

function formatPeriod(invoice: InvoiceListItem) {
  const start = date(invoice.periodStart)
  if (!invoice.periodEnd) {
    return formatDate(start, 'MMM d, yyyy')
  }

  const end = date(invoice.periodEnd)
  if (start.getFullYear() === end.getFullYear()) {
    return `${formatDate(start, 'MMM d')} – ${formatDate(end, 'MMM d, yyyy')}`
  }
  return `${formatDate(start, 'MMM d, yyyy')} – ${formatDate(end, 'MMM d, yyyy')}`
}
