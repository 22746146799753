import { Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { TagColor } from 'helpers/colorToTone'
import { ReactNode } from 'react'

import { IntegrationStatus } from '../types'
import styles from './IntegrationStatusChip.module.scss'

type Props = {
  status: IntegrationStatus
  tooltipLabel?: ReactNode
}

const colorMap: Record<IntegrationStatus, TagColor> = {
  [IntegrationStatus.IssueDetected]: 'red',
  [IntegrationStatus.ChangesPending]: 'yellow',
  [IntegrationStatus.RemovalPending]: 'yellow',
  [IntegrationStatus.Installed]: 'green',
  [IntegrationStatus.NotInstalled]: 'gray',
  [IntegrationStatus.UpdatedEnabled]: 'green',
  [IntegrationStatus.UpdatesDisabled]: 'gray',
  [IntegrationStatus.ReceivingRequests]: 'green',
  [IntegrationStatus.NoActivity]: 'gray',
}

export default function IntegrationStatusChip({ status, tooltipLabel }: Props) {
  return (
    <div data-testid='integration-status' className={clsx(styles.statusChip, styles[colorMap[status]])}>
      {tooltipLabel ? (
        <Tooltip title={tooltipLabel}>
          <Typography variant='bodyS' color='inherit'>
            {status}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant='bodyS' color='inherit'>
          {status}
        </Typography>
      )}
    </div>
  )
}
