import { BadgeTone } from '@compass/components'

export type TagColor = 'yellow' | 'blue' | 'red' | 'orange' | 'green' | 'purple' | 'gray' | 'darkGray' | 'black'

/**
 * Helper function to convert legacy color values to new Badge tones.
 * This maintains backwards compatibility while we migrate to the new Badge component.
 * @deprecated Use BadgeTone directly instead of TagColor
 */

export const colorToTone = (color: TagColor): BadgeTone => {
  switch (color) {
    case 'yellow':
      return 'warning'
    case 'blue':
      return 'info'
    case 'red':
      return 'danger'
    case 'orange':
      return 'default'
    case 'green':
      return 'success'
    case 'purple':
      return 'special'
    case 'gray':
    case 'darkGray':
      return 'secondary'
    case 'black':
      return 'void'
  }
}
