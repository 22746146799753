import { IntegrationProvider } from 'models'

import { SUPPORT_EMAIL, WEBSITE_URL } from './env'

export const SUPPORT_EMAIL_MAILTO = `mailto:${SUPPORT_EMAIL}`

// Docs
export const DOCS_ROOT = 'https://dev.fingerprint.com'
export const DOCS_URL = `${DOCS_ROOT}/docs`
export const PRIVACY_URL = `${DOCS_URL}/privacy-policy`
export const TERMS_URL = `${DOCS_URL}/terms-of-service`
export const DOCS_QUICK_START_URL = `${DOCS_URL}/quick-start-guide`
export const DOCS_SUBDOMAIN_INTEGRATION_URL = `${DOCS_URL}/subdomain-integration`
export const DOCS_REQUEST_FILTERING_URL = `${DOCS_URL}/request-filtering`
export const DOCS_WEBHOOKS_URL = `${DOCS_URL}/webhooks`
export const DOCS_SERVER_API = `${DOCS_ROOT}/reference/pro-server-api`
export const DOCS_SEALED_KEYS = `${DOCS_URL}/sealed-client-results`
export const DOCS_MIGRATING_URL = `${DOCS_URL}/migrating-from-previous-versions`
export const DOCS_MIGRATING_FROM_V2 = `${DOCS_MIGRATING_URL}#from-fingerprintjs-pro-version-2`
export const DOCS_MIGRATING_FROM_V3 = `${DOCS_MIGRATING_URL}#for-users-already-using-fingerprintjs-pro-version-3`
export const DOCS_BOTD_QUICK_START_URL = `${DOCS_URL}/bot-detection-quick-start-guide`
export const DOCS_SEARCH_BOTS_FILTERING = `${DOCS_URL}/search-bots-filtering`
export const DOCS_INTEGRATIONS_SECTION_URL = `${DOCS_URL}/integrations`
export const DOCS_CLOUDFRONT_PROXY_URL = `${DOCS_URL}/cloudfront-proxy-integration`
export const DOCS_AZURE_PROXY_URL = `${DOCS_URL}/azure-proxy-integration`
export const DOCS_WEBHOOK_SECURITY_URL = `${DOCS_URL}/webhooks-security#header-signature`
export const DOCS_PUBLIC_MANAGEMENT_API = `${DOCS_ROOT}/reference/management-api`
export const DOCS_MULTIPLE_ENVIRONMENTS_URL = `${DOCS_URL}/multiple-environments`
export const DOCS_CLOUDFRONT_LAMBDA_URL_FORMAT = `${DOCS_URL}/cloudfront-proxy-integration-v2#step-62-provide-the-management-function-details-to-the-fingerprint-dashboard`
export const DOCS_CLOUDFRONT_v2 = `${DOCS_URL}/cloudfront-proxy-integration-v2`
export const DOCS_CLOUDFRONT_v2_MGMT_LAMBDA_DETAILS = `${DOCS_URL}/cloudfront-proxy-integration-v2#step-61-find-the-management-function-details-in-aws`
export const DOCS_SCRIPT_URL_PATTERN_URL = `${DOCS_ROOT}/reference/load-function#scripturlpattern`
export const DOCS_SMART_SIGNALS_OVERVIEW = `${DOCS_URL}/smart-signals-overview`
export const DOCS_SUSPECT_SCORE_WEIGHTS = `${DOCS_URL}/suspect-score#suspect-score-weights`

export const DOCS_INTEGRATIONS: Record<IntegrationProvider, string> = {
  akamai: `${DOCS_URL}/akamai-proxy-integration`,
  azure: `${DOCS_URL}/azure-proxy-integration`,
  cloudflare: `${DOCS_URL}/cloudflare-integration`,
  cloudFront: `${DOCS_URL}/cloudfront-proxy-integration-v2`,
  gtm: `${DOCS_URL}/fingerprintjs-pro-google-tag-manager`,
  segment: `${DOCS_URL}/segment`,
  fastlyVcl: `${DOCS_URL}/fastly-vcl-proxy-integration`,
  fastlyCompute: `${DOCS_URL}/fastly-compute-proxy-integration`,
  customProxy: `${DOCS_URL}/custom-proxy-integrations`,
}

export const DOCS_CLOUDFLARE_WIZARD = {
  root: `${DOCS_URL}/cloudflare-integration`,
  accountId: `${DOCS_URL}/cloudflare-integration#cloudflare-account-id`,
  apiToken: `${DOCS_URL}/cloudflare-integration#cloudflare-api-token`,
}

// Website
export const CONTACT_SALES_URL = `${WEBSITE_URL}/contact-sales/`
export const PRICING_PAGE_URL = `${WEBSITE_URL}/pricing/`
export const SUPPORT_PAGE_URL = `${WEBSITE_URL}/support/`
export const PRODUCT_BOTD = `${WEBSITE_URL}/products/bot-detection/`

// Github
export const FPJS_GITHUB_ORG = 'https://github.com/fingerprintjs'
export const NODE_JS_REPO_URL = `${FPJS_GITHUB_ORG}/fingerprintjs-pro-server-api-node-sdk`
export const BOTD_README_URL = `${FPJS_GITHUB_ORG}/BotD#botd-currently-in-beta`

// Misc
export const CNAME_RECORD_WIKI_URL = 'https://en.wikipedia.org/wiki/CNAME_record'
const DNS_LOOKUP_BASE_URL = 'https://dnschecker.org'
export const PRODUCTBOARD_PUBLIC_ROADMAP = 'https://roadmap.fingerprint.com'
export const PRODUCTBOARD_PUBLIC_ROADMAP_SUBMISSION_URL = `${PRODUCTBOARD_PUBLIC_ROADMAP}/tabs/3-launched/submit-idea`
export const DEMO_HUB_URL = 'https://demo.fingerprint.com/'
export const DISCORD_INVITE_URL = 'https://discord.com/invite/ad6R2ttHVX'

export function dnsLookupURL(type: 'CNAME' | 'A', domain: string) {
  return `${DNS_LOOKUP_BASE_URL}/#${type}/${domain}`
}
