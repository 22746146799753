import { CloudflareIntegration } from 'models'

export enum IntegrationProvider {
  Akamai = 'akamai',
  Azure = 'azure',
  Cloudflare = 'cloudflare',
  CloudFront = 'cloudFront',
  GTM = 'gtm',
  Segment = 'segment',
  FastlyVCL = 'fastlyVcl',
  FastlyCompute = 'fastlyCompute',
  CustomProxy = 'customProxy',
}

export interface IntegrationData {
  provider: IntegrationProvider
}

export interface CloudflareIntegrationData extends IntegrationData, CloudflareIntegration {
  provider: IntegrationProvider.Cloudflare
}
